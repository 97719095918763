import { BrowserRouter, Route, Routes } from 'react-router';
import { AnimeList } from './AnimeList';
import { AnimeDetails } from './AnimeDetails';
import { About } from './About';

export const AnimeRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<AnimeList />} />
      <Route path="/about" element={<About />} />
      <Route path="/anime/:id" element={<AnimeDetails />} />
      <Route path="/:year/:sort" element={<AnimeList />} />
      <Route path="/*" element={<AnimeList />} />
    </Routes>
  </BrowserRouter>
);

import { useParams } from 'react-router';
import { LayoutContainer } from './LayoutContainer';
import { useEffect, useState } from 'react';
import { TopAnimeData } from '../types/TopAnime';
import { AnimeDetailsSkeleton } from './AnimeDetails.skeleton';

type AnimeDetailsData = TopAnimeData;

export const AnimeDetails = () => {
  const { id } = useParams();
  const [anime, setAnime] = useState<AnimeDetailsData | null>(null);

  const getanimeDetails = async () => {
    const response = await fetch('/.netlify/functions/getAnime', {
      method: 'POST',
      body: JSON.stringify({ id }),
    });

    const data: AnimeDetailsData = await response.json();

    setAnime(data);
  };

  useEffect(() => {
    getanimeDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!anime) return <AnimeDetailsSkeleton />;

  const {
    title_english,
    title_japanese,
    synopsis,
    trailer,
    rank,
    score,
    popularity,
    streaming,
  } = anime;

  const title = title_english || title_japanese;

  const TrailerContainer = ({ url, title }: { url: string; title: string }) => (
    <div className="w-full max-w-screen-lg">
      <div className="relative w-full pt-[56.25%]">
        <iframe
          className="absolute top-0 left-0 w-full h-full"
          src={url}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={`Trailer - ${title}`}
        ></iframe>
      </div>
    </div>
  );

  return (
    <LayoutContainer hideSearchMenu>
      <div className="px-2 py-2 md:px-12 md:py-4 flex flex-col items-center justify-center text-white gap-2 md:gap-8">
        {trailer?.embed_url!! ? (
          <TrailerContainer url={trailer.embed_url} title={title} />
        ) : null}

        <div className="flex flex-col items-center justify-center">
          <p className="text-white font-semibold">{title}</p>
          <div className="flex flex-row gap-4">
            <p className="font-semibold text-xs text-purple-500">
              {score}/10 ✨
            </p>
            <p className="font-semibold text-xs text-emerald-500">
              # {rank?.toLocaleString()} 🏅
            </p>
            <p className="font-semibold text-xs text-rose-500">
              # {popularity?.toLocaleString()} 🔥
            </p>
          </div>
          <div className="flex flex-row gap-6 items-center justify-center p-2">
            {/* <p className="text-sm">Binge it here: </p> */}
            {streaming.map(({ name, url }) => (
              <a href={url} target="_blank" rel="noopener noreferrer">
                <img
                  className="rounded shadow-lg h-6"
                  src={`https://www.google.com/s2/favicons?sz=64&domain=${url}`}
                  alt={`${name} favicon`}
                />
              </a>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-2 items-center justify-center">
          <p className="font-semibold text-lg">Synopsis</p>
          <p className="text-white">{synopsis}</p>
        </div>
      </div>
    </LayoutContainer>
  );
};

import { useEffect, useState } from 'react';
import { TopAnimeData } from '../types/TopAnime';
import { SortTypes } from './SearchMenu';
import { AnimeContainer } from './AnimeContainer';
import { AnimeListSkeleton } from './AnimeList.skeleton';
import { useParams } from 'react-router';
import { LayoutContainer } from './LayoutContainer';

export const AnimeList = () => {
  const params = useParams();
  const year = params.year ? parseInt(params.year) : 2024;
  const sortBy = (params.sort || 'popularity') as SortTypes;
  const limit = 100;

  const [animes, setAnimes] = useState<TopAnimeData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const sendRequest = async ({
    limit,
    year,
    sortBy,
  }: {
    limit: number;
    year: number;
    sortBy: string;
  }) => {
    setIsLoading(true);
    const response = await fetch('/.netlify/functions/getAnimes', {
      method: 'POST',
      body: JSON.stringify({
        limit,
        year,
        sortBy,
      }),
    });

    const data = (await response.json()) as TopAnimeData[];
    // setAnimes(data);

    // This hack should not even be here, this is because the backend is not ensuring unique mal_id
    const uniqueAnimesIds = [
      ...new Set<Number>(data.map((anime) => anime.mal_id)),
    ];

    const uniqueAnimes: TopAnimeData[] = uniqueAnimesIds
      .map((id) => data.find((anime) => anime.mal_id === id))
      .filter((anime) => anime !== undefined) as TopAnimeData[];

    setIsLoading(false);
    setAnimes(uniqueAnimes);
  };

  useEffect(() => {
    sendRequest({
      limit,
      sortBy,
      year,
    });
  }, [limit, sortBy, year]);

  return (
    <LayoutContainer>
      <div className="text-white p-4 w-full flex flex-row flex-wrap items-start justify-center gap-4">
        {!isLoading ? (
          animes.map((anime) => (
            <AnimeContainer
              {...anime}
              key={anime.title + anime.title_english + anime.title_japanese}
            />
          ))
        ) : (
          <AnimeListSkeleton />
        )}
      </div>
    </LayoutContainer>
  );
};

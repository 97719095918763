import { PropsWithChildren } from 'react';
import { SearchMenu } from './SearchMenu';

type NavigationBarProps = PropsWithChildren<{ hideSearchMenu?: boolean }>;

export const NavigationBar = ({
  children,
  hideSearchMenu,
}: NavigationBarProps) => (
  <div className="w-full bg-bingeanime-background md:bg-transparent md:backdrop-blur-sm text-white text-lg font-semibold p-4 md:px-24 fixed z-10">
    <div className="flex flex-col md:flex-row gap-2 md:gap-8 items-center">
      <a
        href="/"
        className="font-semibold text-ellipsis overflow-hidden text-nowrap text-violet-300"
      >
        BingeMyAnime
      </a>

      {!hideSearchMenu ? <SearchMenu /> : null}
      <p className="text-sm font-semibold text-violet-300">
        Looking for the top anime to binge? Look no further!
      </p>
    </div>
  </div>
);

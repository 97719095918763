export const AnimeContainerSkeleton = () => (
  <div className="w-40 md:w-56 flex flex-col items-center rounded overflow-hidden gap-2">
    <div className="w-full h-56 md:h-80 bg-slate-400 rounded" />
    <div className="flex flex-col gap-2 w-56">
      <div className="w-24 h-3 bg-slate-400 rounded self-center" />
      <div className="w-24 h-3 bg-slate-400 rounded" />
      <div className="w-24 h-3 bg-slate-400 rounded" />
      <div className="w-24 h-3 bg-slate-400 rounded" />
    </div>
  </div>
);

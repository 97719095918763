export const Footer = () => (
  <div className="w-full bg-bingeanime-background md:backdrop-blur-sm text-white text-lg font-semibold p-4 md:px-24 fixed z-10 bottom-0">
    <div className="flex flex-col md:flex-row gap-2 md:gap-8 items-center justify-center">
      <a
        href="/about"
        className="font-normal text-ellipsis overflow-hidden text-nowrap text-sm text-violet-300"
      >
        About
      </a>
    </div>
  </div>
);

import { useParams, useNavigate } from 'react-router';

const years = Array.from({ length: 2024 - 1990 + 1 }, (_, i) => 1990 + i);
const sortingTypes = ['rank', 'popularity', 'score'];

export type SortTypes = 'rank' | 'popularity' | 'score';

export const SearchMenu = () => {
  const params = useParams();
  const navigate = useNavigate();

  const Select = ({ children, ...rest }: React.ComponentProps<'select'>) => (
    <select
      {...rest}
      className="text-black capitalize font-semibold text-sm rounded bg-red"
    >
      {children}
    </select>
  );

  const Option = ({ children, ...rest }: React.ComponentProps<'option'>) => (
    <option {...rest} className="bg-red-50">
      {children}
    </option>
  );

  const onDropdownChange = (
    event: React.ChangeEvent<HTMLSelectElement>,
    key: string
  ) => {
    const { value } = event.target;
    const previousState = { year, sort };
    const newState = { ...previousState, [key]: value };

    const newUrl = `/${newState.year}/${newState.sort}`;
    navigate(newUrl);
  };

  const year = params.year ? Number(params.year) : 2024;
  const sort = params.sort || 'popularity';

  return (
    <div className="text-white flex flex-row gap-4">
      <label className="flex gap-2">
        <Select value={year} onChange={(e) => onDropdownChange(e, 'year')}>
          <Option value="" className="bg-red">
            Select a year
          </Option>
          {years.map((year) => (
            <Option key={year} value={year}>
              {year}
            </Option>
          ))}
        </Select>
      </label>
      <label className="flex gap-4">
        <Select value={sort} onChange={(e) => onDropdownChange(e, 'sort')}>
          <Option value="">Select sort type</Option>
          {sortingTypes.map((type) => (
            <Option key={type} value={type}>
              {type}
            </Option>
          ))}
        </Select>
      </label>
    </div>
  );
};

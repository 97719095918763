import { LayoutContainer } from './LayoutContainer';

const faq = [
  {
    question: 'What is this?',
    answer:
      'Binge my anime!™ is a web-app that seeks to solve the problem of "I want to binge the best most recent anime, or binge watch the best anime of last year because I need to catch up and don\'t wanna miss out on last years gems.". \nIt does so by optimizing the discovery-to-enjoying of anime by having the top anime at a glance, to watching the trailer and sypnosis and links to all available streaming apps. Fast.',
  },
  {
    question: 'Why?',
    answer:
      "Two reasons. Firstly, it's been an ongoing issue for me to find the top most recent anime to watch and catch up, and I would constanly have to look for the \"top\" ranked anime and then figuring out if it's still airing and where could I watch. It's really easy to miss out on great anime, specially when figuring out what's best to watch and where to watch it. So I wanted to build something to address this issue. Secondly, I'm prepping for full-stack development job interviews and I wanted to speed run building a web app with a vague problem so that I could define it, as well as define the solution, from a first principles and system design and architecture perspective and all the way down to execution across the stack.",
  },
  {
    question: 'How?',
    answer: `Get ready to nerd out! This web app is a serverless single-page application built with React, based on Create React App. It uses Tailwind CSS for its design framework and React Router for navigation.
    
The backend is powered by serverless architecture, utilizing Lambda functions to handle requests. These requests interact with a custom anime database populated using MyAnimeList data via Jikan's API. Additional data processing was applied to optimize query performance. The database itself is hosted on a MongoDB cluster, with Mongoose managing schemas and data handling.

In some cases, the app directly leverages Jikan’s API through its TypeScript implementation, Jikan-TS. All of this backend functionality is seamlessly managed through serverless Lambda functions.

Finally, the app is hosted on Netlify, which serves the frontend via its CDN and dynamically handles Lambda functions.`,
  },
  {
    question: 'What are the different rankings for?',
    answer: `Currently you can get the anime filtered by year, and sorted by one of three algorithms. Score is number from 0 to one given by MyAnimeList. Rank refers to the position of the anime if they were all (not just for a single year) ranked by Score. Popularity is based off how many users added the anime to their MyAnimeList. This can lead to different results because while some animes might score really high it does not guarantee their popularity. Some lower scored animes are much more popular than higher ranking ones.`,
  },
];

export const About = () => (
  <LayoutContainer hideSearchMenu>
    <div className="px-2 py-2 md:px-12 md:py-4 flex flex-col items-center justify-center text-white gap-2 md:gap-8">
      {faq.map(({ question, answer }) => (
        <div className="max-w-screen-lg">
          <p className="text-lg font-bold">{question}</p>
          <p>{answer}</p>
        </div>
      ))}
    </div>
  </LayoutContainer>
);

import { LayoutContainer } from './LayoutContainer';

export const AnimeDetailsSkeleton = () => {
  return (
    <LayoutContainer hideSearchMenu>
      <div className="px-2 py-2 md:px-12 md:py-4 flex flex-col items-center justify-center text-white gap-2 md:gap-8">
        <div className="animate-pulse w-full flex flex-row flex-wrap items-start justify-center gap-4"></div>
        <div className="w-full max-w-screen-lg bg-slate-400 rounded">
          <div className="relative w-full pt-[56.25%]">
            <div className="absolute top-0 left-0 w-full h-full"></div>
          </div>
        </div>

        <div className="flex flex-col items-center justify-center">
          <p className="text-white font-semibold w"></p>
          <div className="flex flex-row gap-4">
            <div className="w-24 h-3 bg-slate-400 rounded" />
            <div className="w-24 h-3 bg-slate-400 rounded" />
            <div className="w-24 h-3 bg-slate-400 rounded" />
          </div>
          <div className="flex flex-row gap-6 items-center justify-center p-2">
            {[1, 2, 3, 4].map(() => (
              <div className="w-4 h-4 bg-slate-400 rounded" />
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-4 items-center justify-center w-full">
          <div className="w-24 h-4 bg-slate-400 rounded" />
          <div className="w-full h-3 bg-slate-400 rounded" />
          <div className="w-full h-3 bg-slate-400 rounded" />
          <div className="w-full h-3 bg-slate-400 rounded" />
          <div className="w-full h-3 bg-slate-400 rounded" />
        </div>
      </div>
    </LayoutContainer>
  );
};

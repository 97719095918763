import { PropsWithChildren } from 'react';
import { NavigationBar } from './NavigationBar';
import { Footer } from './Footer';

export const LayoutContainer = ({
  children,
  hideSearchMenu,
}: PropsWithChildren<{ hideSearchMenu?: boolean }>) => (
  <>
    <NavigationBar hideSearchMenu={hideSearchMenu} />
    <div className="w-full py-32 md:py-16">{children}</div>
    <Footer />
  </>
);

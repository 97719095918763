import { TopAnimeData } from '../types/TopAnime';

export const AnimeContainer = ({
  images: { jpg },
  title,
  title_japanese,
  title_english,
  rank,
  score,
  popularity,
  ...rest
}: TopAnimeData) => (
  <div className="w-40 md:w-56 flex flex-col items-center rounded overflow-hidden">
    <a href={`/anime/${rest.mal_id}`}>
      <img
        src={jpg.image_url}
        alt={title_english || title_japanese}
        className="rounded"
      />
    </a>
    <div className="w-full">
      <p className="font-semibold text-sm font-base text-center p-1 w-full text-nowrap text-ellipsis overflow-hidden">
        {title_english || title_japanese}
      </p>
      <p className="font-semibold text-xs text-purple-500">{score}/10 ✨</p>
      <p className="font-semibold text-xs text-emerald-500">
        # {rank?.toLocaleString()} 🏅
      </p>
      <p className="font-semibold text-xs text-rose-500">
        # {popularity?.toLocaleString()} 🔥
      </p>
    </div>
  </div>
);
